/* eslint-disable no-undef */
// 返回顶部
export function backTop(dom) {
  // scroll 页面滚动事件
  $(window).scroll(() => {
    // scrollTop滚动条距离顶部
    const sTop = $(window).scrollTop()
    if (sTop >= 500) { // 条件可根据需要进行修改
      dom.fadeIn()
    } else {
      dom.fadeOut()
    }
  })
  // 点击回到顶部
  dom.click(() => {
    $('body,html').animate({ scrollTop: 0 }, 500)
  })
}