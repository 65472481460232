/* eslint-disable no-undef */
import '../css/iconfont.css'
import '../css/bootstrap.min.css'
import '../css/common.scss'
import '../css/modules.scss'
require('bootstrap')


import { backTop } from './common.js'
$(() => {
  backTop($('.back-top'))
})